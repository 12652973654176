// @flow
import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import type { Node as ReactNode } from 'react';
import '../../assets/css/global.css';

type Props = {
  children: ReactNode,
  title: string,
  description?: string
};

const Layout = ({ children, title, description }: Props) => (
  <React.Fragment>
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
    <div className="fixed w-full">
      <nav className="flex items-center justify-between flex-wrap bg-white p-6 container w-full z-10 top-0 h-24 mx-auto">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <Link to="/">
            <img src="/media/twilam-logo.svg" className="h-10" />
          </Link>
        </div>


      </nav>
    </div>
    <div className="pt-24">
    </div>
    <div>
      {children}
    </div>
    <div className="pt-24">
    </div>
  </React.Fragment>
);

export default Layout;

import React, { useRef, useEffect } from 'react';

type Props = {
  title?: string,
  children: React.Node
};

const Page = ({ title, children }: Props) => {
  const pageRef = useRef();

  useEffect(() => {
    //    pageRef.current.scrollIntoView();
  });

  return (
    <div className="">
      {title && <h1>{title}</h1>}
      <div className="flex-grow">
        {children}
      </div>
      <div className="flex-grow container mx-auto shadow-2xl">
        <div class="bg-gray-100 px-6 py-8 rounded max-w-lg shadow-md mx-auto" name="contact">
          <div className="text-3xl xl:text-5xl text-center font-display text-twilamblue">
            Contact us
      </div>
          <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="deleferemelo">
            <input type="hidden" name="deleferemelo" />
            <p>
              <input type="text" name="name" placeholder="Your Name" className="block border border-grey-light w-full lg:w-1/2 p-3 rounded mb-4" />
            </p>
            <p>
              <input type="email" name="email" placeholder="Email" className="block border border-grey-light w-full lg:w-1/2 p-3 rounded mb-4" />
            </p>
            <p>
              <textarea name="message" placeholder="Message" rows="4" className="block border border-grey-light w-full p-3 rounded mb-4"></textarea>
            </p>
            <p>
              <button type="submit" className="text-center py-3 rounded bg-twilamblue text-white focus:outline-none my-1 mx-1 p-2">Send</button>
            </p>
          </form>
        </div>
        <div class="flex flex-wrap w-full justify-between font-body p-4">
          <div class="text-xl xl:text-2xl mx-auto">UK: +44 20 8638 0163</div>
          <div class="text-xl xl:text-2xl mx-auto">USA: +1 (646) 789 5970</div>
          <div class="text-xl xl:text-2xl mx-auto">China: +86 186 0129 8984</div>
        </div>
        <div class="flex flex-wrap w-full justify-between font-body p-4 text-gray-600">
          <div class="text-sm xl:text-lg mx-auto">Copyright © Twilam Limited 2017-2019</div>
        </div>
      </div>
    </div>
  );
};

export default Page;